<template>
  <vx-card class="setting-menu-page main-box top-zero-radius md:w-1/2">

    <profile-main-box :profile="getCompanyProfile"
                      :actions-list="actionsList"
                      @address="showClubAddressPromptStatus = true"
                      @tel="showClubTelPromptStatus = true"
                      @financialSetting="showClubFinancialPromptStatus = true"
                      @treasury="showClubTreasuryPromptStatus = true"
                      @message="showClubMessagePromptStatus = true"
                      @faq="showClubFAQPromptStatus = true"
                      @rules="showClubRulesPromptStatus = true"
    />

    <!-- show company info setting prompt -->
    <vs-prompt
        class="prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="showClubInfoPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('saveCompanyInfoSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.clubInfo.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubInfoPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <club-info-setting @edited="showClubInfoPromptStatus = false, getCompany"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show address prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubAddressPromptStatus"
      @close="showClubAddressPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editAddressSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.address.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubAddressPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <address-setting @edit="showClubAddressPromptStatus = false"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show tell prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubTelPromptStatus"
      @close="showClubTelPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editTelSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.tel.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubTelPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <tel-setting @edit="showClubTelPromptStatus = false"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show treasury prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubTreasuryPromptStatus"
      @close="showClubTreasuryPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editTreasurySettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.Treasury.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubTreasuryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <treasury-settings @edit="showClubTreasuryPromptStatus = false"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show financial prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubFinancialPromptStatus"
      @close="showClubFinancialPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editFinancialSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.financial.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubFinancialPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <financial-setting @edited="showClubFinancialPromptStatus = false"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show SMS prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubMessagePromptStatus"
      @close="showClubMessagePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editMessageSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.message.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubMessagePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <message-setting @edit="showClubMessagePromptStatus = false"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- show faq list prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubFAQPromptStatus"
      @close="showClubFAQPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('insertFaq')">
              <custom-icon icon="PLUS" color="success"/>
            </div>
            <!--            <div class="ml-2" @click="handleClick('saveFaq')">-->
            <!--              <custom-icon icon="SAVE" color="success"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.faq.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubFAQPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <faq-list/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>


    <!-- show rules prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubRulesPromptStatus"
      @close="showClubRulesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="editRules">
              <custom-icon icon="SAVE" color="success"/>
            </div>
            <!--            <div class="ml-2" @click="handleClick('saveFaq')">-->
            <!--              <custom-icon icon="SAVE" color="success"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.rules.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubRulesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <custom-validate-textarea :label="$t('setting.rules.labels.rules')"
                                  align="right"
                                  rtl
                                  :classes="{'w-full': true}"
                                  v-model="club.rules"/>
      </div>
    </vs-prompt>

    <vs-button v-show="false"
               id="editClubInfo"
               @click="showClubInfoPromptStatus = true"/>
  </vx-card>
</template>

<script>
  import vueMarkdown from 'vue-markdown'
  import {getSetting, editSetting, editAppSetting} from '../../../../http/requests/setting'
  import {getAvatarUrl} from "../../../../assets/js/functions"
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import FaqList from "../faq/list/faqList";
  import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
  import AddressSetting from "../address/addressSetting";
  import EmployeeList from "../employee/list/employeeList";
  import TimesList from "../../times/list/timesList";
  import TelSetting from "../tel/telSetting";
  import MessageSetting from "../message/messageSetting";
  import TreasurySettings from "../treasury/TreasurySettings";
  import ClubInfoSetting from "../clubInfo/clubInfoSetting.vue";
  import FinancialSetting from "@/views/admin/settings/financial/financialSetting.vue";

  export default {
    name: 'userProfile',
    components: {
      FinancialSetting,
      ClubInfoSetting,
      TreasurySettings,
      MessageSetting,
      TelSetting,
      TimesList,
      EmployeeList, AddressSetting, CustomValidateTextarea, FaqList, CustomIcon, ProfileMainBox, vueMarkdown
    },
    metaInfo () {
      return {
        title: this.$t('setting.title')
      }
    },
    data() {
      return {
        showClubInfoPromptStatus: false,
        showClubAddressPromptStatus: false,
        showClubTelPromptStatus: false,
        showClubTreasuryPromptStatus: false,
        showClubFinancialPromptStatus: false,
        showClubMessagePromptStatus: false,
        showClubFAQPromptStatus: false,
        showClubRulesPromptStatus: false,
        timesListExist: false,
        actions: {
          toolbar: [
            {
              id: 'editClubInfo',
              icon: 'icon-edit-2',
              iconPack: 'feather',
              color: 'warning'
            }
          ]
        },
        company: {},
        club: {
          name: '',
          avatar: '',
          faq: [],
          rules: {
            value: '',
            isValid: true
          },
          supportNumber: '',
          whatsAppNumber: '',
          address: ''
        },
        actionsList: [
          /*{
            event: 'address',
            header: '',
            i18n: 'setting.actions.address',
            icon: 'icon-map-pin',
            iconPack: 'feather'
          },
          {
            event: 'tel',
            header: '',
            i18n: 'setting.actions.tel',
            icon: 'icon-phone',
            iconPack: 'feather'
          },*/
          {
            event: 'treasury',
            header: '',
            i18n: 'setting.actions.treasury',
            icon: 'icon-dollar-sign',
            iconPack: 'feather'
          },
          {
            name: 'financialYearSetting',
            header: '',
            permission: 'financial_year.show',
            i18n: 'setting.financialYear.title',
            icon: 'icon-dollar-sign',
            iconPack: 'feather'
          },
          {
            event: 'financialSetting',
            header: '',
            // permission: 'financial_year.show',
            i18n: 'setting.financial.title',
            icon: 'icon-dollar-sign',
            iconPack: 'feather'
          },
          {
            name: 'languagesSetting',
            header: '',
            i18n: 'setting.actions.language',
            icon: 'icon-map-pin',
            iconPack: 'feather'
          },
          {
            event: 'message',
            header: '',
            i18n: 'setting.actions.message',
            icon: 'icon-mail',
            iconPack: 'feather'
          },
          {
            event: 'faq',
            header: '',
            i18n: 'setting.actions.faq',
            icon: 'icon-sliders',
            iconPack: 'feather'
          },
          {
            event: 'rules',
            header: '',
            i18n: 'setting.actions.rules',
            icon: 'icon-shield',
            iconPack: 'feather'
          },
        ]
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
      this.getSetting()
      this.getCompany()
    },
    computed: {
      getCompanyProfile() {
        const company = {
          avatar: this.club.avatar ? getAvatarUrl(this.club.avatar) : 'https://s16.picofile.com/file/8421955526/USERAL_logo.png',
          name: this.club.name,
          asiderAvatar: [
            {
              content: this.club.name || ''
            },
            {
              content: this.club.supportNumber || '',
              preContent: this.$t('setting.asiderAvatar.support')
            },
            {
              content: this.club.whatsAppNumber || '',
              preContent: this.$t('setting.asiderAvatar.whatsApp')
            }
          ],
          details: [],
          importantData: []
        }

        return company
      }
    },
    methods: {
      getCompany() {
        this.company = JSON.parse(localStorage.getItem('clubInfo'))
      },

      getSetting() {
        getSetting().then((response) => {
          const club = response.data.data.club
          const app = response.data.data.app
          const faq = JSON.parse(app.faq)
          this.club.name = club.club_name
          this.club.avatar = club.club_avatar || ''
          this.club.faq = faq.questions
          this.club.rules.value = app.rules || ''
          this.club.supportNumber = club.support_number || ''
          this.club.whatsAppNumber = club.whatsapp_number || ''
          this.club.address = club.address || ''

          const clubInfo = {
            clubName: this.club.name,
            clubLogo: getAvatarUrl(this.club.avatar)
          }
          this.$store.dispatch('setting/setClubInfo', clubInfo)
        })

      },
      editRules() {
        if (this.club.rules.isValid) {
          const payload = {
            rules: this.club.rules.value
          }
          editAppSetting(payload).then(response => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.message.title'),
              text: this.$t('setting.notifications.editRules.success'),
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'success'
            })
            this.showClubRulesPromptStatus = false
          }).catch(error => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.error.title'),
              text: error.response.data.message || error.response.data.error,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .setting-menu-page {

    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>
