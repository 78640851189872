<template>
  <div class="message-setting">
    <custom-validate-input :classes="{'w-full': true}"
                           align="right"
                           is-empty
                           :label="$t('setting.message.labels.messengerUsername')"
                           :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"
                           v-model="club.messengerUsername"/>

    <custom-validate-input :classes="{'w-full': true}"
                           align="right"
                           is-empty
                           :label="$t('setting.message.labels.messengerPassword')"
                           :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"
                           v-model="club.messengerPassword"/>

    <custom-validate-input :classes="{'w-full': true}"
                           align="right"
                           :label="$t('setting.message.labels.messengerSenderNumber')"
                           :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"
                           v-model="club.messengerSenderNumber"/>

<!--    <div class="message-templates">
      <span class="title">{{ $t('setting.message.labels.templates') }}</span>

      <custom-validate-input :classes="{'w-full': true}"
                             align="right"
                             :label="$t('setting.message.labels.login')"
                             :regex="templateRegex"
                             :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"
                             v-model="club.loginTemplate"/>

      <custom-validate-input :classes="{'w-full': true}"
                             align="right"
                             :label="$t('setting.message.labels.signup')"
                             :regex="templateRegex"
                             :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"
                             v-model="club.signupTemplate"/>

&lt;!&ndash;      <custom-validate-input :classes="{'w-full': true}"&ndash;&gt;
&lt;!&ndash;                             align="right"&ndash;&gt;
&lt;!&ndash;                             :label="$t('setting.message.labels.confirmInfo')"&ndash;&gt;
&lt;!&ndash;                             :regex="templateRegex"&ndash;&gt;
&lt;!&ndash;                             :invalid-text="$t('setting.message.invalidTexts.onlyEnglish')"&ndash;&gt;
&lt;!&ndash;                             v-model="club.confirmTemplate"/>&ndash;&gt;
    </div>-->

    <div class="message-fields pt-2">
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableCancelReserve')"
                       v-model="club.enableCancelReserveTimeMessage" />
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableDailyReport')"
                       v-model="club.enableDailyReportMessage" />
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableNewReceiveByClub')"
                       v-model="club.enableNewReceiveByClubMessage" />
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableNewReceiveByCustomer')"
                       v-model="club.enableNewReceiveByCustomerMessage" />
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableNewReserveTime')"
                       v-model="club.enableNewReserveTimeMessage" />
      </div>
      <div v-if="club.enableNewReserveTimeMessage" class="message-field mb-2">
        <custom-validate-textarea :label="$t('setting.message.labels.newReserveTimeMessagePattern')"
                                  align="right"
                                  rtl
                                  is-empty
                                  :classes="{'w-full': true}"
                                  v-model="club.newReserveTimeMessagePattern"/>
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableNewReserveCoach')"
                       v-model="club.enableNewReserveCoachMessage" />
      </div>
      <div class="message-field mb-2">
        <custom-switch :label="$t('setting.message.labels.enableWelcome')"
                       v-model="club.enableWelcomeMessage" />
      </div>
    </div>

    <vs-button v-show="false"
               id="editMessageSettingBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import {getMessengerSetting, editMessengerSetting} from '../../../../http/requests/setting'
  import {getProvinces} from "../../../../http/requests/provinces";
  import CustomSelect from "../../../../components/customSelect/customSelect";
  import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
  import CustomPhoneNumberInput from "../../../../components/customInput/customPhoneNumberInput";
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import CustomSwitch from "../../../../components/customInput/customSwitch.vue";

  export default {
    name: "messageSetting",
    components: {CustomValidateTextarea, CustomSwitch, CustomValidateInput, CustomPhoneNumberInput},
    metaInfo () {
      return {
        title: this.$t('setting.message.title')
      }
    },
    data() {
      return {
        keyRegex: this.$validator('regex.message.key'),
        templateRegex: this.$validator('regex.message.template'),
        club: {
          loginTemplate: {
            value: '',
            isValid: true
          },
          signupTemplate: {
            value: '',
            isValid: true
          },
          confirmTemplate: {
            value: '',
            isValid: true
          },
          messengerUsername: {
            value: '',
            isValid: true
          },
          messengerPassword: {
            value: '',
            isValid: true
          },
          messengerSenderNumber: {
            value: '',
            isValid: true
          },
          newReserveTimeMessagePattern: {
            value: '',
            isValid: true
          },
          enableCancelReserveTimeMessage: false,
          enableDailyReportMessage: false,
          enableNewReceiveByClubMessage: false,
          enableNewReceiveByCustomerMessage: false,
          enableNewReserveTimeMessage: false,
          enableNewReserveCoachMessage: false,
          enableWelcomeMessage: false,
        },
        actions: {
          toolbar: [
            {
              id: 'editMessageSettingBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: []
        }
      }
    },

    created() {
      // setTimeout(() => {
      //   this.$store.dispatch('updateNavbarActions', this.actions)
      //   this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      //   this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      // }, 50)

      this.getSetting()
    },

    methods: {
      getSetting() {
        getMessengerSetting().then((response) => {
          const message = response.data.data
          this.club.messengerUsername.value = message.messenger_username || ''
          this.club.messengerPassword.value = message.messenger_password || ''
          this.club.messengerSenderNumber.value = message.messenger_sender_number || ''
          this.club.newReserveTimeMessagePattern.value = message.new_reserve_time_message_pattern || ''
          this.club.enableCancelReserveTimeMessage = message.enable_cancel_reserve_time_message === '1'
          this.club.enableDailyReportMessage = message.enable_daily_report_message === '1'
          this.club.enableNewReceiveByClubMessage = message.enable_new_receive_by_club_message === '1'
          this.club.enableNewReceiveByCustomerMessage = message.enable_new_receive_by_customer_message === '1'
          this.club.enableNewReserveCoachMessage = message.enable_new_reserve_coach_message === '1'
          this.club.enableNewReserveTimeMessage = message.enable_new_reserve_time_message === '1'
          this.club.enableWelcomeMessage = message.enable_welcome_message === '1'
        })

      },

      sendData() {
        if (this.club.messengerSenderNumber.isValid &&
          this.club.messengerUsername.isValid) {
          const payload = {
            messenger_username: this.club.messengerUsername.value,
            messenger_password: this.club.messengerPassword.value,
            messenger_sender_number: this.club.messengerSenderNumber.value,
            new_reserve_time_message_pattern: this.club.newReserveTimeMessagePattern.value,
            enable_cancel_reserve_time_message: this.club.enableCancelReserveTimeMessage,
            enable_daily_report_message: this.club.enableDailyReportMessage,
            enable_new_receive_by_club_message: this.club.enableNewReceiveByClubMessage,
            enable_new_receive_by_customer_message: this.club.enableNewReceiveByCustomerMessage,
            enable_new_reserve_coach_message: this.club.enableNewReserveCoachMessage,
            enable_new_reserve_time_message: this.club.enableNewReserveTimeMessage,
            enable_welcome_message: this.club.enableWelcomeMessage,
          }
          editMessengerSetting(payload).then(response => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.message.title'),
              text: this.$t('setting.notifications.editMessage.success'),
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'success'
            })

            this.$emit('edit')
          }).catch(error => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.error.title'),
              text: error.response.data.message || error.response.data.error,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .message-setting {
    padding: 0 10px;

    .message-templates {
      position: relative;
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: .5rem;
      padding: 10px 10px 0;

      .title {
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 5px;
        backdrop-filter: sepia(1);
      }
    }
  }
</style>
